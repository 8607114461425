"use strict";

$('#lc-customer-table #select-all').click(function () {
    $('#lc-customer-table .toggle-customer').prop('checked', $(this).prop('checked'));
});
$('#lc-customer-table tbody tr').click(function (e) {
    var isChecked = false;

    if (!$(e.target).hasClass('toggle-customer')) {
        var $checkbox = $(this).find('.toggle-customer');
        isChecked = $checkbox.prop('checked');

        $checkbox.prop('checked', !isChecked);
    }
    else {
        isChecked = $(e.target).prop('checked');
    }

    if (isChecked) {
        $('#customer-select-list #select-all').prop('checked', false);
    }
});

var $editor = $('#editor');

$('.variables .variable').click(function (e) {
    e.preventDefault();

    var value = $(this).data('value') + '&nbsp;'; //fix for insert with cursor
    var node = tinymce.activeEditor.insertContent(value);

});

$('#preview-tab').click(function () {
    var $form = $('#markup-form');
    var $preview = $('#preview');
    var $progress = $('#preview-progress');

    $preview.empty();

    $.ajax({
        type: 'POST',
        url: $(this).data('action'),
        data: {
            '_token': $form.find('input[name=_token]').val(),
            'template': $form.find('#editor').val()
        },
        dataType: 'json',
        beforeSend: function () {
            $progress.show();
        },
        success: function (data) {
            $preview.html(data.result.replace(/\n/g, "<br />"));
        },
        complete: function () {
            $progress.hide();
        }
    });
});
