"use strict";
var Catalogue;
Catalogue = function () {
    var IMG_PATH = '';

    function setUp() {
        var Catalogue = React.createClass({
            render: function () {
                return (
                    <div className='catalogue'>
                        <TrendingItemContainer/>
                        <TagItemContainer/>
                    </div>
                );
            }
        });
        var TrendingItemContainer = React.createClass({
            getInitialState: function () {
                var self = this;
                var result = {data: []};
                $.ajax({
                    url: '/api/catalog/recent'
                }).done(function (data) {
                    console.log(data);
                    self.setState({data: data});
                });

                return result
            },
            render: function () {
                var createItem = function (item) {
                    return <TrendingItem key={item.id} data={item}/>
                };

                return (
                    <div className='TrendingItemContainer row'>
                        {this.state.data.map(createItem)}
                    </div>
                );
            }
        });
        var TrendingItem = React.createClass({
            switchPage: function () {
                window.location.href = "/management/catalogus/" + this.props.data.id;
            },
            render: function () {
                var date;
                date = new Date(this.props.data.created_at);
                var drawTags = function (tag) {
                    return <span key={tag.id} className="label label-primary">{tag.name}</span>
                };
                return (
                    <div className="col-md-4">
                        <div className="panel clickable-panel" data-target="" onClick={this.switchPage}>
                            <img src={IMG_PATH + "/campaign/" + this.props.data.id + ".jpg"} className="img-responsive"
                                 alt="Responsive image"/>

                            <div className="panel-content">
                                <h3 className="m-t-0">{this.props.data.name}</h3>
                            </div>
                        </div>
                    </div>
                );
            }
        });
        var TagItemContainer = React.createClass({
            getInitialState: function () {
                var self = this;
                var result = {
                    data: {}
                };
                $.ajax({
                    url: '/api/catalog'
                }).done(function (data) {
                    self.setState({data: data});
                    console.log(data);
                    $('#tab_nav a:first').tab('show');
                    console.log('test');
                });
                return result
            },
            render: function () {
                var self = this;
                return (
                    <div className="row">
                        <div className="col-md-12">
                            <div className="panel">
                                <div className="panel-content">
                                    <ul id='tab_nav' className="nav nav-tabs">
                                        {Object.keys(this.state.data).map(function (item, i) {
                                            return <li key={i}><a href={"#tab_" + i} data-toggle="tab">{item}</a></li>
                                        })}
                                    </ul>
                                    <div className="tab-content p-t-30">
                                        {Object.keys(this.state.data).map(function (item, i) {
                                            return <TagTabBody key={i} i={i} data={self.state.data[item]}/>
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }
        });
        var TagTabBody = React.createClass({
            render: function () {
                return (
                    <div className='tab-pane fade clearfix' id={"tab_" + this.props.i}>
                        <div className="row">
                            {this.props.data.map(function (item) {
                                return <TagItem key={item.id} data={item}/>
                            })}
                        </div>
                    </div>
                );
            }
        });
        var TagItem = React.createClass({
            switchPage: function () {
                window.location.href = "/management/catalogus/" + this.props.data.id;
            },
            render: function () {
                var date;
                date = new Date(this.props.data.created_at);
                return (
                    <div className="col-md-4">
                        <div className="panel bgl-gray clickable-panel" onClick={this.switchPage}>
                            <div className="panel-content catalogue-item-body">
                                <h3 className="m-t-0">{this.props.data.name}</h3>
                                {this.props.data.tags.map(function (item) {
                                    return <span className="label label-primary">{item.name}</span>
                                })}
                                <p>
                                    {
                                        this.props.data.resources.abstract
                                    }
                                </p>
                            </div>
                            <div className="panel-footer clearfix">
                                <div className="pull-right">

                                </div>
                                <div className="pull-left">
                                    <i className="fa fa-calendar-o"></i> {date.toLocaleDateString()}
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }
        });
        React.render(
            <Catalogue/>,
            $('#lc-catalogue-page')[0]
        );
    }

    return {
        init: function (img_path) {
            IMG_PATH = img_path;
            setUp();
        }
    };
}();
